.info-section-three{
    background-color: #F3F5F6;
    padding: 100px 0 100px;
}
.info-section-three .heading{
    font-size: 34px;
    text-align: center;
    font-weight: 600;
    padding-bottom: 80px;
}
.info-section-three .content-flex{
    display: flex;
    align-items: stretch;
}
.info-section-three .content-flex .item{
    width: 100%;
}
.info-section-three .content-flex .item .item-number{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 2;
    color: #fff;
    position: absolute;
    margin-top: -20px;
    margin-left: 32px;
}
.info-section-three .content-flex .item .divider{
    width: 95%;
    border-top: 1px solid #8b94ae38;
    margin-left: 12%;
}
.info-section-three .content-flex .item:nth-child(3) .divider{
    border-top: 1px solid #8b94ae00;
}

.info-section-three .content-flex .item:nth-child(1) .item-number{
    background-image: linear-gradient(#2A7DDB, #18ADE7);
}
.info-section-three .content-flex .item:nth-child(2) .item-number{
    background-image: linear-gradient(#ACA5F7, #C0BBFE);
}
.info-section-three .content-flex .item:nth-child(3) .item-number{
    background-image: linear-gradient(#6DE296, #75EA9E);
}
.info-section-three .content-flex .item .text-content{
    padding: 42px 35px;
    text-align: left;
    width: 100%;
}
.info-section-three .content-flex .item .text-content h1{

}
.info-section-three .content-flex .item .text-content p{
    color: #676e8191;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
    width: 80%;
}
.info-section-three a{
    background-color: #00A5E4;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
    border-radius: 4px;
    padding: 14px 24px;
    margin: 0px auto;
    text-align: center;
    text-decoration: none;
    display: table;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.5px;
}

@media only screen and (max-width: 900px) {
    .info-section-three{
        padding: 70px 0 70px;
        
    }
    .info-section-three .heading{
        font-size: 28px;
        padding-bottom: 62px;
    }
    .info-section-three .content-flex{
        display: block;
    }
    .info-section-three .content-flex .item .text-content{
        width: unset;
    }
    .info-section-three .content-flex .item {
        border-left: 0.5px  solid #8b94ae38
    }
    .info-section-three .content-flex .item .item-number{
        margin-left: -22px;
        margin-top: 59px;
    }
    .info-section-three .content-flex .item .text-content{
        padding: 42px 0px 24px 70px;
    }
    .info-section-three .content-flex .item .text-content h1{
        width: 100%;
        font-size: 20px;
    }
    .info-section-three .content-flex .item .text-content p{
        width: 100%;
    }
    .info-section-three .content-flex .item .divider{
        display: none;
    }
    .info-section-three .content-flex .item:nth-child(1) .text-content{
        padding-top: 0px;
    }
    .info-section-three .content-flex .item:nth-child(1) .item-number{
        margin-top: 0px;
    }
    .info-section-three .content-flex{
        margin-bottom: 40px;
    }
    
}
