header.hero{
    height: 52vh;
    padding-top: 178px;
    padding-bottom: 178px;
    background-image: url('../../../assets/img/png/1x/chunk.png'), linear-gradient(#2A7DDB, #18ADE7);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
}
header.hero .hero-text-container{
    max-width: 40%;
}
header.hero .hero-text-container h1{
    max-width: 62%;
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.5;
}
header.hero .hero-text-container p{
    max-width: 90%;
    color: #ffffffa6;
    font-size: 18px;    
    font-weight: 300;
    line-height: 1.8;
}

header.hero .app-download-container{
    display: flex;
    padding-top: 10px;
}
header.hero .app-download-container a{
    background-repeat: no-repeat;
    background-size: contain;
    height: 49px;
    width: 164px;
    color: transparent;
    margin-right: 20px;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 24%);
    border-radius: 6px;
    background-color: #000;
    padding: 2px;
}
header.hero .app-download-container .app-store{
    background-image: url('../../../assets/img/png/1x/app-store.png');
}
header.hero .app-download-container .play-store{
    background-image: url('../../../assets/img/png/1x/google-play.png');
}

@media only screen and (max-width: 800px) {
    header.hero{
        height: 66vh;
        padding-top: 26%;
        padding-bottom: 16%;
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
    }
    header.hero .hero-text-container{
        max-width: 100%;
    }
    header.hero .hero-text-container h1 {
        max-width: 100%;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.4;
    }
    header.hero .hero-text-container p{
        max-width: 100%;
    }
    header.hero .app-download-container a{
        background-size: contain;
        height: 36px;
        width: 164px;
        padding: 3px;
        margin-right: 14px;
    }
}