footer{
    background-color: #182548;
    padding-top: 40px;
}

footer .footer-flex{
    display: flex;
    padding: 10px 0px;
}
footer .footer-flex .logo img{
    height: 38px;
}
footer .footer-flex .footer-link-container{

}
footer .footer-flex .footer-link-container{
    margin-left: auto;
}
footer .footer-flex .footer-link-container ul{
    list-style-type: none;
}
footer .footer-flex .footer-link-container ul li{
    display: inline-block;
}
footer .footer-flex .footer-link-container ul li a{
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
}
footer .divider{
    background-color: #7A8195;
    height: 0.5px;
}
footer .footer-flex .copyright p{
    color: #7A8195;
    font-size: 14px;
}
footer .footer-flex .social{
    margin-left: auto;
}
footer .footer-flex .social ul{
    list-style-type: none;
}
footer .footer-flex .social ul li{
    display: inline-block;
}
footer .footer-flex .social ul li a{
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
}

@media only screen and (max-width: 900px) {
    footer .footer-flex{
        display: block;
    }
    footer .footer-flex .logo img{
        height: 38px;
        margin: 0px auto;
        display: block;
        padding-bottom: 20px;
    }
    footer .footer-flex .footer-link-container ul,
    footer .footer-flex .social ul{
        list-style-type: none;
        text-align: center;
        padding-left: 0px;
    }
    footer .footer-flex .copyright p{
        text-align: center;
    }
}