.card{
    background-color: #fff;
    margin: 20px 20px;
    padding: 16px 46px;
    box-shadow: -1px 0px 15px 2px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
.card .card-flex{
    display: flex;
}
.card .card-flex .img{
    max-width: 180px;
    max-height: 180px;
}
.card .card-flex .img img{
    max-width: 180px;
    width: auto;
    height: 180px;
    object-fit: scale-down;
    overflow: hidden;
}
.card .card-flex .card-text-container{
    padding-left: 18px;
}
.card .card-flex .card-text-container h1{
    font-weight: 600;
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 10px;
}
.card .card-flex .card-text-container p{
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 10px;
}
.card .card-flex .card-text-container a{
    color: #00A5E4;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}
.card .card-flex .card-text-container a svg{
    position: absolute;
}

@media only screen and (max-width: 900px) {
    .card{
        margin: 20px 0px;
        margin-bottom: 40px;
        padding: 20px 20px;
    }
    .card .card-flex .img {
        max-width: unset;
        max-height: unset;
    }
    .card .card-flex .img img{
        width: 66px;
        height: auto;
        margin-top: 14px;
    }
    .card .card-flex .card-text-container h1{
        font-size: 18px;
        margin-bottom: 5px;
    }
    .card .card-flex .card-text-container p{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .card .card-flex .card-text-container a{
        font-size: 12px;
    }
    .card .card-flex .card-text-container a svg{
        height: 20px;
        margin-top: 4px;
    }
}