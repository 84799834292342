@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

*{
	font-family: 'Poppins', sans-serif;
}
body{
    padding: 0px;
    margin: 0px;
	overflow-x: hidden;
}
.container {
    padding-right: 10%;
    padding-left: 10%;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 768px) {
	.container {
		max-width: 750px;
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 970px;
	}
}
@media (min-width: 1200px) {
	.container {
		max-width: 1406px;
	}
}