.info-section-four{
    padding-top: 100px;
}
.info-section-four .content-flex{
    display: flex;
    align-items: stretch;
}
.info-section-four .content-flex .img{
    flex-basis: 0;
    flex: 1.5;
    max-width: 100%;
}
.info-section-four .content-flex .img img{
    max-width: 700px;
}
.info-section-four .section-text-container{
    flex-basis: 0;
    flex-grow: 1;
}
.info-section-four .section-text-container h2{
    color: #36C3F9;
    font-weight: 300;
    font-size: 26px;
    line-height: unset;
}
.info-section-four .section-text-container h1{
    color: #121A2E;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 12px;
}
.info-section-four .section-text-container p{
    color: #676e8191;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
}

.info-section-four .cards-section{
    padding: 100px 50px;
    display: flex;
}

@media only screen and (max-width: 900px) {
    .info-section-four {
        border-bottom: 1px solid #676e8133;
        padding: 70px 0 0px;
    }
    .info-section-four .content-flex{
        display: block;
    }
    .info-section-four .img img{
        width: 100%;
        max-width: 800px;
        height: auto;
        margin-bottom: 20px;
    }
    .info-section-four .section-text-container h2{
        font-size: 22px;
    }
    .info-section-four .section-text-container h1{
        font-size: 30px;
    }
    .info-section-four .section-text-container p{
        font-size: 14px;
    }

    .info-section-four .cards-section{
        padding: 100px 0px;
        display: block;
    }
}

@media only screen and (max-width: 1256px) {
    .info-section-four .cards-section{
        padding: 100px 0px;
        display: block;
    }
}