.info-section-one{
    padding: 100px 0 100px;
}
.info-section-one .content-flex{
    display: flex;
    flex-direction: row;
}
.info-section-one .img{
    flex: 50%;
}
.info-section-one .img img{
    max-width: 100%;
    height: auto;
}
.info-section-one .section-text-container{
    /* flex-basis: 0;
    flex: 1; */
    /* height: 100%;
    margin-top: 5%; */
    flex: 50%;
    padding-left: 10%;
}
.info-section-one .section-text-container h2{
    color: #36C3F9;
    font-weight: 300;
    font-size: 26px;
    margin-bottom: 0px;
}
.info-section-one .section-text-container h1{
    color: #121A2E;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 10px;
}
.info-section-one .section-text-container p{
    color: #676e8191;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
    margin-bottom: 0px;
}

@media only screen and (max-width: 900px) {
    .info-section-one {
        border-bottom: 1px solid #676e8133;
        padding: 70px 0 70px;
    }
    .info-section-one .content-flex{
        flex-direction: column;
    }
    .info-section-one .img {

    }
    .info-section-one .img img{
        width: 100%;
        max-width: 800px;
        height: auto;
        margin-bottom: 20px;
    }
    .info-section-one .section-text-container{
        padding-left: unset;
    } 
    .info-section-one .section-text-container h2{
        font-size: 22px;
    }
    .info-section-one .section-text-container h1{
        font-size: 28px;
    }
    .info-section-one .section-text-container p{
        font-size: 14px;
    }
}