a.btn-icon{
    border: 2px solid;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 4px;
    padding: 14px 24px;
    text-align: center;
    text-decoration: none;
    display: table;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 400;
    letter-spacing: 1.5px;
}
a.btn-icon svg{
    position: absolute;
}
a.btn-icon span{
    padding-left: 32px;
}