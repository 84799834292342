.info-section-two{
    padding: 100px 0 100px;
}
.info-section-two .content-flex{
    display: flex;
    align-items: stretch;
}
.info-section-two .content-flex .item{
    padding: 0px 35px;
    text-align: center;
    width: 33.33%;
    height: auto;
    float: left;
}
.info-section-two .content-flex .item img{
    margin: 0px auto;
}
.info-section-two .content-flex .item h1{
    margin: 18px auto;
}
.info-section-two .content-flex .item p{
    color: #676e8191;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
    width: 90%;
    margin: 12px auto;
}

@media only screen and (max-width: 800px) {
    .info-section-two{
        padding: 70px 0 70px;
        background-color: #fff;
        height: 100%;
        display: flow-root;
    }
    .info-section-two .content-flex{
        display: block;
    }
    .info-section-two .content-flex .item{
        padding: unset;
        text-align: unset;
        width: unset;
        margin-bottom: 40px;
    }
    .info-section-two .content-flex .item p{
        margin-left: 0px;
    }
}