
nav {
    height: 68px;
    width: 100%;
	background-color: #2881dc;
    position: relative;
	padding-top: 20px;
    padding-bottom: 20px;
}
nav img{
	height: 38px;
	padding-top: 0px;
}
nav .container{
	padding-top: 14px;
}
nav .nav-header {
	display: inline;
}
nav .nav-header a{
	width: auto;
	display: inline-table;
}

nav .nav-header > .nav-title {
	display: inline-block;
	font-size: 22px;
	color: #fff;
}

nav .nav-btn {
	display: none;
}

	nav .nav-links {
	display: inline;
	float: right;
	font-size: 18px;
}

nav .nav-links > a {
	display: inline-block;
	padding: 13px 20px;
	text-decoration: none;
	color: #fff;
}
nav .nav-links > a:nth-last-child(2){
    margin-right: 22px;
}

nav #nav-check {
	display: none;
}
  
@media (max-width:800px) {
    nav .nav-btn {
		display: inline-block;
		position: absolute;
		right: 40px;
		top: 6px;
    }
    nav .nav-btn > label {
		display: inline-block;
		width: 27px;
		height: 27px;
		padding: 13px;
    }
    nav .nav-btn > label:hover,nav  #nav-check:checked ~ .nav-btn > label {
      	background-color: rgba(0, 0, 0, 0.3);
    }
    nav .nav-btn > label > span {
		display: block;
		width: 25px;
		height: 10px;
		border-top: 2px solid #eee;
    }
    nav .nav-links {
		position: absolute;
		display: block;
		width: 100%;
		background-color: #2881dc;
		height: 0px;
		transition: all 0.3s ease-in;
		overflow-y: hidden;
		top: 76px;
		left: 0px;
    }
    nav .nav-links > a {
		display: block;
		color: #fff;
    }
    nav #nav-check:not(:checked) ~ .nav-links {
      	height: 0px;
    }
    nav #nav-check:checked ~ .nav-links {
      	height: calc(100vh - 50px);
      	overflow-y: auto;
    }
}

@media only screen and (max-width: 800px) {
	nav .nav-links{
		z-index: 9999;
	}
	nav .nav-links > a {
		display: block;
		color: #fff;
		text-align: center;
	}
	nav .nav-links > a.btn-cta{
		width: 80%;
		display: block;
		text-align: center;
		margin: 0px auto;
	}
	nav .nav-links > a:nth-last-child(2){
		margin-right: unset;
	}
	nav .container {
		padding-top: unset;
	}
	nav .nav-btn > label{
		margin-top: 10px;
	}
}
